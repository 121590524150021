import React from 'react';
import { Card } from 'react-bootstrap';
import founderOne from '../images/Louisvilleheadshotphotographer-52.jpg';
import founderTwo from '../images/H03.jpg';
import ImageFader from '../components/ImageFader';

import ffLogo from '../images/ff_logo_use.png';
import fbtcLogo from '../images/fbtc_logo_use.png';

const AboutUs = () => {

  const images = [
    ffLogo,
    fbtcLogo
  ]

  return (
    <div className='App'>
      <header className="App-header">
        <h1>About Us Page</h1>
        <div>
          <ImageFader images={images} />
        </div>
      </header>

      <div className="AboutUs">
        {/* Founder 1 */}
        <Card className="founder-card">
          <Card.Img variant="top" src={founderOne} alt="Founder 1" />
          <Card.Body>
            <h3 class="card-title">Danielle</h3>
            <Card.Text>
              Head Trainer, Group Fitness Instructor
            </Card.Text>
          </Card.Body>
        </Card>

        {/* Founder 2 */}
        <Card className="founder-card">
          <Card.Img variant="top" src={founderTwo} alt="Founder 2" />
          <Card.Body>
            <h3 class="card-title">Jeremiah</h3>
            <Card.Text>
              Group Fitness Instructor
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      
      <div className="story-section">
        <h2>Our Story</h2>
        <p>
          This dynamic duo was birthed all by our love for taking care of our bodily temple. During a late night gym session at planet fitness, Jeremiah noticed  the nubian queen Danielle and struck up a conversation. Upon what he felt was a for sure win, Danielle left abruptly. So abrupt that Jeremiah couldn't untie his tongue fast enough to ask her out. Over the next 7 days, he traveled to that gym, looking high and low for his new love interest but to no avail. In utter defeat, he continued on with his gym routine as he beat himself up for not following through. About 5 mins into his 1st mile, the life changing shoulder tap happened. It was Danielle grinning from ear to ear, "hey friend" she giggled as she noticed the pure joy in his eyes. He stopped the treadmill in mid session asking to take her out to eat. She agreed and the rest was history. Little did we know that this story would be the Fondation of our company.
        </p>
        <p>
          Since then Fit by the Couple was born and we have trained clients from our hometown, to random people on a cruise ship, to coworkers seeing the routine unfold. From cardio dance classes to high intensity bootcamps, we continue to show the importance of putting FITNESS FORWARD.
        </p>
      </div>
    </div>

  );
};

export default AboutUs;