import React from 'react';
import { Table } from "react-bootstrap";
import { Link } from 'react-router-dom';

import ImageFader from '../components/ImageFader';

import ffLogo from '../images/ff_logo_use.png';
import fbtcLogo from '../images/fbtc_logo_use.png';

// import event from "../images/event_banner.png";

const Events = () => {

  const events = [
    { name: 'Strength & HIIT Training', date: 'Oct. 3rd', location: <Link to='https://secure.rec1.com/CA/san-pablo-ca/catalog'>San Pablo, CA</Link>},
    { name: 'Strength & HIIT Training', date: 'Oct. 10th', location: <Link to='https://secure.rec1.com/CA/san-pablo-ca/catalog'>San Pablo, CA</Link>},
    { name: 'Strength & HIIT Training', date: 'Oct. 17th', location: <Link to='https://secure.rec1.com/CA/san-pablo-ca/catalog'>San Pablo, CA</Link>},
    { name: 'Strength & HIIT Training', date: 'Oct. 24th', location: <Link to='https://secure.rec1.com/CA/san-pablo-ca/catalog'>San Pablo, CA</Link>}
  ];

  const images = [
    ffLogo,
    fbtcLogo
  ]

  return (
    <div className='App'>
      <header className="App-header">
        <h1>Upcoming Events</h1>
        <div>
          <ImageFader images={images} />
        </div>
      </header>
      <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
        <iframe 
          src="https://outlook.office365.com/owa/calendar/1646d8df305f43b1a7373cc167e1f557@putfitnessforward.com/d8022fc6abb84795afb1fdacda45b1389196622122939423998/calendar.html" 
          style={{ 
            width: '90%', 
            height: '600px', 
            border: 'none', 
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
            borderRadius: '8px' 
          }} 
          title="Outlook Calendar">
        </iframe>
      </div>
    </div>
  );
};

// function Item(props) {
//   return(
//     <div class="carousel-container">
//       <img
//       key={props.index}
//       src={props.item.imagePath}
//       alt={props.item.altText}
//       style={{ width: '60%', height: '600px', objectFit: 'contain' }}
//       />
//     </div>
//   )
// }

export default Events;