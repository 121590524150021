import React from 'react';
import './App.css';
import NavigationBar from './NavigationBar';

const App = () => {
  return (
    <>
      <NavigationBar />
    </>
  );
};

export default App;