// Import required libraries
import React from 'react';
import Carousel  from 'react-material-ui-carousel'; // Updated import
import './App.css';
import './NavigationBar.css';
import YoutubeEmbed from './components/YouTubeEmbed';
import ImageFader from './components/ImageFader';

import image1 from './images/fbtc_2.jpg';
import image2 from './images/fbtc_1.jpg';
import image3 from './images/FINAL2.jpg';
import ffLogo from './images/ff_logo_use.png';
import fbtcLogo from './images/fbtc_logo_use.png';

// Create a functional component for the home page
const Home = () => {

  const carouselItems = [
    {
      imagePath: image1,
      altText: 'Fitness Image'
    },
    {
      imagePath: image2,
      altText: 'Test'
    },
    {
      imagePath: image3,
      altText: 'Test'
    }
  ]
  
  const images = [
    ffLogo,
    fbtcLogo
  ]

  return (
    <div className="App">
      <header className="App-header">
          <h1>Welcome to Fitness Forward</h1>
          <p>Let us help you put Fitness Forward!</p>
          <div>
            <ImageFader images={images} />
          </div>
      </header>
      
     {/* <div className="carousel-container">
        <div id="demo" class="carousel slide" data-bs-ride="carousel">

          <div class="carousel-indicators">
            <button type="button" data-bs-target="#demo" data-bs-slide-to="0" class="active"></button>
            <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
            <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
          </div>

          <div class="carousel-item active">
            <img src={image1} alt='Test' className="img-fluid w-100"/>
          </div>
          <div class="carousel-item" >
            <img src={image2} alt='Test' className="img-fluid w-100"/>
          </div>
          <div class="carousel-item">
            <img src={image3} alt='Test' className="img-fluid w-100"/>
          </div>

          <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
            <span class="carousel-control-prev-icon"></span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
            <span class="carousel-control-next-icon"></span>
          </button>
        </div>
      </div> */}

      <Carousel 
        autoPlay={true}
        indicatorContainerProps={{
          style: {
            padding: "0px",
            marginTop: "-40px",
            marginBottom: "10px",
            zIndex: "1",
            position: "relative",
            backgroundColor: "transparent"
          },
        }}
      >
        {carouselItems.map((item, index) => (
          <Item key={index} item={item} />
        ))}
      </Carousel>
      
      {/* <h1 className="services-header">Services</h1> */}
      <div className="services-container">
        <header className="services-header">
        </header>
        <div className="youtube-container">
          <YoutubeEmbed embedId='dOSWBnQCumQ'/>
        </div>
      </div>
    </div> 
  )
};

function Item(props) {
  return(
    <div class="carousel-container">
      <img
      key={props.index}
      src={props.item.imagePath}
      alt={props.item.altText}
      style={{ width: '45%', height: '700px', objectFit: 'contain' }}
      />
    </div>
  )
}

export default Home; // Export the App component as the default