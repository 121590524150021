import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import emailjs from 'emailjs-com';

import ImageFader from '../components/ImageFader';

import ffLogo from '../images/ff_logo_use.png';
import fbtcLogo from '../images/fbtc_logo_use.png';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email using EmailJS
    sendEmail(formData)
      .then((response) => {
        alert('Email sent successfully!');
      })
      .catch((error) => {
        alert('Failed to send email. Please try again later.');
      });

    // Clear form fields after submission
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  // Function to send email using EmailJS
  const sendEmail = (data) => {
    const templateParams = {
      to_email: 'info@putfitnessforward.com', // Replace with your email address
      from_name: data.name,
      from_email: data.email,
      message: data.message,
    };

    // Replace these with your actual EmailJS service details
    const serviceID = 'service_1axifnb';
    const templateID = 'template_ofymdqd';
    const userID = 'm7PMIi7nGKbGg_SYW';

    return emailjs.send(serviceID, templateID, templateParams, userID);
  };

  const images = [
    ffLogo,
    fbtcLogo
  ]
  
  return (
    <div className='App'>
      <header className="App-header">
        <h1>Contact Us!</h1>
        <div>
          <ImageFader images={images} />
        </div>
      </header>

      <div className='form-container'>
        <Form onSubmit={handleSubmit} className="contact-form">
          <div class='row'>
            <div class='col'>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="First Last"
                  required
                />
              </Form.Group>
            </div>
            <div class='col'>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="name@email.com"
                  required
                />
              </Form.Group>
            </div>
          </div>
          <Form.Group controlId="message">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Enter your message"
              required
            />
          </Form.Group>

          <div className='button-container'>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
};

export default Contact;