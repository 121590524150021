import React from 'react';
import { Card, CardGroup } from 'react-bootstrap';
import pic_1 from '../images/Screenshot_20230718_170258_Gallery.jpg';
import pic_2 from '../images/Screenshot_20230718_170304_Gallery.jpg';
import pic_3 from '../images/Screenshot_20230718_170310_Gallery.jpg';
import pic_4 from '../images/Screenshot_20230718_170314_Gallery.jpg';
import pic_5 from '../images/IMG-1670.jpg';
import pic_6 from '../images/IMG-1690.jpg';
import pic_7 from '../images/IMG-1696.jpg';
import pic_8 from '../images/IMG-1715.jpg';
import pic_9 from '../images/IMG-1734.jpg';
import pic_10 from '../images/IMG-2617.jpg';
import pic_11 from '../images/IMG-2618.jpg';
import pic_12 from '../images/IMG-2619.jpg';
import pic_13 from '../images/IMG-2620.jpg';
import pic_14 from '../images/IMG-2621.jpg';
import pic_15 from '../images/IMG-2622.jpg';

import ImageFader from '../components/ImageFader';

import ffLogo from '../images/ff_logo_use.png';
import fbtcLogo from '../images/fbtc_logo_use.png';


const Gallery = () => {

  const images = [
    ffLogo,
    fbtcLogo
  ]

  return (
      <div className='App'>
        <header className="App-header">
          <h1>Gallery</h1>
          <div>
            <ImageFader images={images} />
          </div>
        </header>

        <CardGroup className="gallery-grid">
          <Card>
            <Card.Img variant="top" className="gallery-item" src={pic_10} alt="Image 3" />
          </Card>

          <Card>
            <Card.Img variant="top" className="gallery-item" src={pic_11} alt="Image 2" />
          </Card>

          <Card>
            <Card.Img variant="top" className="gallery-item" src={pic_12} alt="Image 3" />
          </Card>

          <Card>
            <Card.Img variant="top" className="gallery-item" src={pic_13} alt="Image 3" />
          </Card>

          <Card>
            <Card.Img variant="top" className="gallery-item" src={pic_14} alt="Image 3" />
          </Card>

          <Card>
            <Card.Img variant="top" className="gallery-item" src={pic_15} alt="Image 3" />
          </Card>

          <Card>
            <Card.Img variant="top" className="gallery-item" src={pic_1} alt="Image 1" />
          </Card>

          <Card>
            <Card.Img variant="top" className="gallery-item" src={pic_2} alt="Image 2" />
          </Card>

          <Card>
            <Card.Img variant="top" className="gallery-item" src={pic_3} alt="Image 3" />
          </Card>

          <Card>
            <Card.Img variant="top" className="gallery-item" src={pic_4} alt="Image 3" />
          </Card>

          <Card>
            <Card.Img variant="top" className="gallery-item" src={pic_5} alt="Image 1" />
          </Card>

          <Card>
            <Card.Img variant="top" className="gallery-item" src={pic_6} alt="Image 2" />
          </Card>

          <Card>
            <Card.Img variant="top" className="gallery-item" src={pic_7} alt="Image 3" />
          </Card>

          <Card>
            <Card.Img variant="top" className="gallery-item" src={pic_8} alt="Image 3" />
          </Card>

          <Card>
            <Card.Img variant="top" className="gallery-item" src={pic_9} alt="Image 3" />
          </Card>

          {/* Add more images as needed */}
        </CardGroup> 

        {/* <!-- Modal --> */}
        <div class="modal fade" id="gallery-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-body">
                <img src={pic_1} className="modal-img" alt="Test"/>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Gallery;